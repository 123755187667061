<template>
  <div>
    <home-old v-if="pageType === 1"/>
    <workbench v-if="pageType === 2"/>
    <!-- 直播倒计时 -->
    <div class="live" v-dragmove v-if="livingList.length && !hideLiving && loaded" @click="toLivingList">
      <img src="https://jsh-oss-static.jsh.com/prod/image/2025/2/21/e12066e7-d598-4c39-9c7c-31e4d037df13.png" alt="关闭" class="close" @click="onClose">
      <img
        src="https://jsh-oss-static.jsh.com/prod/image/2025/2/20/6cd66cb0-c242-49c0-a58d-a3b42150b03e.png"
        alt="直播预告"
        width="140"
        height="140"
        draggable="false"
        class="icon-img"
      >
      <div class="having" v-if="countdownTime === ''">
        <span>直播中</span>
      </div>
      <div class="notStart" v-else>
        <span class="d-block status">直播倒计时</span>
        <span class="d-block time">{{ countdownTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import HomeOld from './home-old.vue'
import Workbench from '../workbench/workbench.vue'
import { CloudUser, CloudWorkbench } from '../../apis'
import { getYLHTokenAnyway } from '../../utils/auth'

export default {
  name: 'Home',
  components: {
    HomeOld,
    Workbench
  },
  data () {
    return {
      pageType: null,
      hideLiving: false, // 关闭直播悬浮窗
      livingList: [],
      interval: null, // 定时器
      countdownTime: '', // 倒计时
      loaded: false // 接口加载完毕
    }
  },
  directives: {
    dragmove (el, binding, vnode) {
      /**
       * 获取客户端可见内容的高度
       *
       * @returns {number}
       */
      const getClientHeight = () => {
        return window.innerHeight || Math.min(document.documentElement.clientHeight, document.body.clientHeight)
      }

      /**
       * 获取客户端可见内容的宽度
       *
       * @returns {number}
       */
      const getClientWidth = () => {
        return window.innerWidth || Math.min(document.documentElement.clientWidth, document.body.clientWidth)
      }

      /**
       * startX = null:获取鼠标相对于元素（左上顶点）的x轴坐标（移动前坐标）
       * startX != null:获取移动后的左上顶点x轴坐标
       *
       * e.clientX:鼠标相对客户端（客户端左上顶点）的x轴坐标
       * el.offsetLeft:元素顶点（左上顶点）相对客户端（客户端左上顶点）的x轴坐标（元素必须脱离文档流，position: fixed or absolute）
       * el.clientWidth:元素宽度
       *
       * @param el
       * @param e
       * @param startX
       * @returns {number}
       */
      const getX = (el, e, startX) => {
        if (startX === null) {
          // 返回鼠标相对于元素（左上顶点）的x轴坐标
          return e.clientX - el.offsetLeft
        }

        // 客户端可视宽度
        const clientWidth = getClientWidth()
        // 元素自身宽度
        const elWidth = el.clientWidth

        // 移动到x轴位置
        let x = e.clientX - startX
        // 水平方向边界处理
        if (x <= 0) {
          // x轴最小为0
          x = 0
        } else if (x + elWidth > clientWidth) {
          // x是左上顶点的坐标，是否触碰到右边边界（超出可视宽度）要通过右顶点判断，所以需要加上元素自身宽度
          x = clientWidth - elWidth
        }

        return x
      }

      /**
       * startY = null:获取鼠标相对于元素（左上顶点）的y轴坐标（移动前坐标）
       * startY != null:获取移动后的左上顶点y轴坐标
       *
       * e.clientY:鼠标相对客户端（客户端左上顶点）的y轴坐标
       * el.offsetTop:元素顶点（左上顶点）相对客户端（客户端左上顶点）的y轴坐标（元素必须脱离文档流，position: fixed or absolute）
       * el.clientHeight:元素高度
       *
       * @param el
       * @param e
       * @param startY
       * @returns {number}
       */
      const getY = (el, e, startY) => {
        if (startY === null) {
          // 返回鼠标相对于元素（左上顶点）的y轴坐标
          return e.clientY - el.offsetTop
        }

        // 客户端可视高度
        const clientHeight = getClientHeight()
        // 元素自身高度
        const elHeight = el.clientHeight

        // 移动到y轴位置
        let y = e.clientY - startY
        // 垂直方向边界处理
        if (y <= 0) {
          // y轴最小为0
          y = 0
        } else if (y + elHeight > clientHeight) {
          // 同理，判断是否超出可视高度要加上自身高度
          y = clientHeight - elHeight
        }

        return y
      }

      /**
       * 监听鼠标按下事件（PC端拖动）
       *
       * @param e
       */
      el.onmousedown = (e) => {
        vnode.context.isDrag = false

        // 获取当前位置信息 (startX,startY)
        // const startX = getX(el, e, null)
        const startY = getY(el, e, null)

        /**
         * 监听鼠标移动事件
         *
         * @param e
         */
        document.onmousemove = (e) => {
          // 标记正在移动，解决元素移动后点击事件被触发的问题
          vnode.context.isDrag = true

          // 更新元素位置（移动元素）
          // el.style.left = getX(el, e, startX) + 'px'
          el.style.top = getY(el, e, startY) + 'px'
        }

        /**
         * 监听鼠标松开事件
         */
        document.onmouseup = () => {
          // 移除鼠标相关事件，防止元素无法脱离鼠标
          const elitems = document.getElementById('PopupulBox')
          const elitemstop = elitems.getBoundingClientRect().top
          const elitemsheight = elitems.clientHeight
          if (elitemstop < 10) {
            elitems.className = 'popup-ul-down'
          } else if (elitemstop > elitemsheight) {
            elitems.className = 'popup-ul'
          }
          document.onmousemove = document.onmouseup = null
        }
      }

      /**
       * 监听手指按下事件（移动端拖动）
       * @param e
       */
      el.ontouchstart = (e) => {
        // 获取被触摸的元素
        const touch = e.targetTouches[0]
        // 获取当前位置信息 (startX,startY)
        const startX = getX(el, touch, null)
        const startY = getY(el, touch, null)

        /**
         * 监听手指移动事件
         * @param e
         */
        document.ontouchmove = (e) => {
          // 获取被触摸的元素
          const touch = e.targetTouches[0]
          // 更新元素位置（移动元素）
          el.style.left = getX(el, touch, startX) + 'px'
          el.style.top = getY(el, touch, startY) + 'px'
        }

        /**
         * 监听手指移开事件
         */
        document.ontouchend = () => {
          // 移除touch相关事件，防止元素无法脱离手指
          document.ontouchmove = document.ontouchend = null
        }
      }
    }
  },
  methods: {
    getHomepageShowtype () {
      return new Promise((resolve, reject) => {
        this.$http.get(CloudWorkbench.getYlhHomepageByWhiteList, {
          terminal: 2
        }, {
          headers: {
            Authorization: 'Bearer ' + getYLHTokenAnyway()
          }
        }).then(res => {
          if (res.data.success && res.data.data) {
            this.pageType = res.data.data?.pageType || 1
            resolve()
          } else {
            this.pageType = 1
            reject(new Error('获取工作台白名单失败'))
          }
        }).catch(() => {
          this.pageType = 1
          reject(new Error('获取工作台白名单失败'))
        })
      })
    },
    // 倒计时
    updateCountdown (currentTime, liveStartTime) {
      const now = new Date().getTime()
      // const distance = liveStartTime - currentTime
      const distance = liveStartTime - now
      // 时间计算
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      // 设置倒计时显示
      // document.getElementById('countdown').innerHTML = days + '天 ' + hours + '小时 ' + minutes + '分钟 ' + seconds + '秒 '
      this.countdownTime = (days ? days + '天' : '') + (hours ? hours + '小时' : '') + (minutes ? minutes + '分钟' : '') + seconds + '秒'
      this.loaded = true
      // 如果时间到了，可以停止计时器并显示其他信息
      if (distance < 0) {
        this.countdownTime = ''
        clearInterval(this.interval)
      }
    },
    listLiveInfoList () {
      this.$http.get(CloudUser.listLiveInfoList, {
        pageNo: 1,
        pageSize: 1
      }).then(res => {
        if (res.data.success && res.data.data) {
          this.livingList = res.data.data.livingList || []
          if (this.livingList.length) {
            const currentDate = new Date(this.livingList[0].currentDate).getTime()
            const liveStartDate = new Date(this.livingList[0].liveStartDate).getTime()
            console.log('currentDate:', currentDate)
            console.log('liveStartDate:', liveStartDate)
            // 设置定时器，每秒更新一次倒计时
            this.interval = setInterval(() => {
              this.updateCountdown(currentDate, liveStartDate)
            }, 1000)
          }
        } else {
          console.log(res.data.errorMsg)
        }
      }).catch(() => {
        console.log('直播大厅进入列表接口异常')
      })
    },
    // 关闭
    onClose () {
      this.hideLiving = true
    },
    // 跳转直播大厅列表
    toLivingList () {
      const newUrl = this.$router.resolve({
        path: '/pc-generic/live-list'
      })
      window.open(newUrl.href, '_blank')
    }
  },
  created () {
    this.getHomepageShowtype().then(res => {
      const localPageType = JSON.parse(localStorage.getItem('pageType') || 1)
      if (!(localPageType && localPageType === this.pageType)) {
        localStorage.setItem('pageType', this.pageType)
      }
    })
    this.listLiveInfoList()
  }
}
</script>

<style lang="scss" scoped>
.live{
  position: fixed;
  bottom: 169px;
  right: 0px;
  z-index: 9000;
  width: 140px;
  height: 140px;
  cursor: pointer;
  .close{
    z-index: 9001;
    position: absolute;
    width: 28px;
    height: 28px;
    right: 0;
  }
  .having{
    width: 100%;
    position: absolute;
    bottom: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
  }
  .notStart{
    width: 100%;
    position: absolute;
    bottom: 16px;
    .status{
      color: rgb(255, 255, 255);
      font-size: 10px;
      font-weight: 500;
      text-align: center;
    }
    .time{
      color: rgb(255, 255, 255);
      font-size: 10px;
      font-weight: 500;
      text-align: center;
    }
  }
  .icon-img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
