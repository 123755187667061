// ------home page
import {
  CloudBase,
  CloudFund,
  CloudGoods,
  CloudOrder,
  CloudStock,
  CloudUser,
  CloudBi, CloudNotice, CloudTask
} from '../../apis'
import {
  formatDate,
  getFullAddress,
  hasPermission,
  yilihuoAppQrCode
} from '../../utils'
import DefaultAppImg from '@/assets/images/default-app.png'
import bus from '@/bus'
import AliPlayer from './ali-video-player/ali-video-player.vue'
import StockWaitDialog from './stock-wait-dialog/stock-wait-dialog.vue'
import todoList from './todoList/todoList.vue'

import { throttle } from '@jsh/helper/directives'
import Vue from 'vue'

Vue.directive('throttle', throttle)

export default {
  components: { AliPlayer, StockWaitDialog, todoList },
  data () {
    return {
      yilihuoAppQrCode,
      sumOrder: 0, // 待处理业务-分销订单-待处理收款-订单数量
      sumAmount: '', // 待处理业务-分销订单-待处理收款-未确认金额
      pendingReceivablesPermission: false, // 待处理业务-分销订单-待处理收款是否有权限
      pendingRetailOrderPermission: false, // 待处理业务-零售订单-是否有权限
      pendingAuditOrderPermission: false, // 待处理业务-分销订单-待审核订单是否有权限
      pendingStockShipmentPermission: false, // 待处理业务-库存待办-待发货是否有权限
      pendingStockOutPermission: false, // 待处理业务-库存待办-待出库是否有权限
      pendingStockReceivePermission: false, // 待处理业务-库存待办-待收货是否有权限
      pendingStockInPermission: false, // 待处理业务-库存待办-待入库是否有权限
      orderTargetPermission: false, // 分销业务-目标数据(当月汇总)-是否有权限
      orderDealDataPermission: false, // 分销业务-交易数据-是否有权限
      retailSitePermission: false, // 零售业务-全部门店-是否有权限
      retailStockPermission: false, // 零售业务-库存-是否有权限
      officialInformationPermission: false, // 公告-官方公告是否有权限
      adDialogVisible: false,
      adPermission: false, // 广告链接权限
      editName: false, // 修改姓名弹框
      staffName: '', // 修改后的名称
      yuanStaffName: '', // 用户名字
      noAduitCount: 0, // 待处理业务-分销订单-待审核订单-数量
      summaryFilter: '月度', // 分销业务当月汇总筛选条件
      dataFilter: '当日', // 分销业务交易数据筛选条件
      siteFilter: '当月', // 零售业务门店筛选条件
      stockDataFilter: '近一个月', // 库存待办时间筛选
      memberId: null,
      userId: null,
      nameDisabled: false, // 防重复点击
      userPhoneNumber: null, // 用户手机号
      userPhoneNumberI: null, // 用户手机号 ***
      isTrueFalse: false,
      phoneMessage: null,
      shopId: null, // 店铺id
      tvYlhApkUrl: null, // 智家云店tv版apk下载地址
      aid: '',
      officialInformation: true, // 是否为官方公告
      recommendMenuList: [], // 功能推荐列表
      contractCenter: hasPermission('ContractCenter'), // 是否可以显示签约中台入口
      DefaultAppImg: DefaultAppImg, // 常用功能默认图标
      systemMsgList: [], // 官方公告列表
      shopMsgList: [], // 商家公告列表
      adList: [], // 轮播广告列表
      jshLink: process.env.VUE_APP_CUSTOMER_CENTER_HOST, // 巨商汇链接地址
      uhomeBuinessLink: process.env.VUE_APP_UHOME_MERCHANTS, // 智家商户平台链接地址
      signGroundLink: '/webv2/base/sign-ground-page', // 签约中台
      inStockSimpleProcess: true, // 入库简流程 默认开启
      outStockSimpleProcess: true, // 出库简流程 默认开启
      schoolDialogVisible: false, // 营销学院未绑定弹出框
      adList1: [],
      orderItemsCount: 0, // 分销业务-交易数量-台数
      orderCount: 0, // 分销业务-交易数量-订单数
      orderTotalAmount: 0, // 分销业务-交易数量-金额
      sumTargetAmount: 0, // 分销业务-目标汇总-目标
      sumActualAmount: 0, // 分销业务-目标汇总-实际
      rate: 0, // 分销业务-目标汇总-达成率
      siteDealAmount: 0, // 零售销售-全部门店-成交金额
      siteDealPersonCount: 0, // 零售销售-全部门店-成交人数
      siteOrderCount: 0, // 零售销售-全部门店-订单量
      siteOrderSkuCount: 0, // 零售销售-全部门店-订单商品量
      waitDeliveryOrderCount: 0, // 待处理业务-库存待办-待发货订单数
      waitInboundOrderCount: 0, // 待处理业务-库存待办-待入库订单数
      waitOutboundOrderCount: 0, // 待处理业务-库存待办-待出库订单数
      waitReciveOrderCount: 0, // 待处理业务-库存待办-待收货订单数
      stockWaitDialogConfig: {
        show: false
      },
      auditCount: 0, // 待处理业务-销售订单-待审核
      collectionAmt: 0, // 待处理业务-销售订单-待收款金额
      collectionCount: 0, // 待处理业务-销售订单-待收款
      storeAvgAge: 0, // 平均年龄
      storeDaysInventory: 0, // 周转天数
      inventoryTime: 0, // 截止时间
      inboundSkuCount: 0, // 零售业务-库存-入库商品数
      outboundSkuCount: 0, // 零售业务-库存-出库商品数
      stockProductCount: 0, // 零售业务-库存-型号数
      stockSkuCount: 0, // 零售业务-库存-库存台数
      averageSampleTime: 0, // 你可能关心-平均出样时间
      itemModelPercentage: '0%', // 你可能关心-型号出样达成率
      sampleCount: 0, // 你可能关心-样机台数
      // 我的任务
      taskObj: {},
      pendingBusinessRefreshLoading: false,
      pendingRetailRefreshLoading: false,
      retailBusinessRefreshLoading: false,
      distributionBusinessRefreshLoading: false,
      passagelevelist: [], // 一级分类列表
      showTap: false, // tabs控制
      noticeTypeActive: '',
      levelist: [] // 未读消息列表
    }
  },
  computed: {
    // 视图用功能推荐列表
    viewRecommendMenuList () {
      const arr = []
      for (let i = 0; i < this.recommendMenuList.length; i = i + 3) {
        arr.push(this.recommendMenuList.slice(i, i + 3))
      }
      return arr
    },
    // 常用功能列表
    commonMenuList () {
      return this.$store.state.commonMenuList
    },
    // 常用功能菜单管理弹出框
    manageModal () {
      return this.$store.state.isDisplayAppManageModal
    }
  },
  methods: {
    // 更换姓名
    changeName () {
      if (this.staffName === '') {
        this.$message({
          type: 'error',
          message: '请输入姓名',
          customClass: 'lai-message-box'
        })
        return
      }
      const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g')
      if (!reg.test(this.staffName)) {
        this.$message({
          type: 'error',
          message: '姓名中必须包含汉字',
          customClass: 'lai-message-box'
        })
        return
      }
      const regChina = /^[a-zA-Z0-9\u4e00-\u9fa5·-]+$/
      if (!regChina.test(this.staffName)) {
        this.$message({
          type: 'error',
          message: '姓名中包含特殊字符',
          customClass: 'lai-message-box'
        })
        return
      }
      this.nameDisabled = true
      this.$http.post(CloudUser.updateUserStaffInfo, {
        staffName: this.staffName
      })
        .then(res => {
          const { success, data, errorMsg } = res.data
          if (success && data) {
            this.editName = false
            this.$message({
              type: 'success',
              message: '姓名修改成功',
              customClass: 'lai-message-box'
            })
            this.nameDisabled = false
          } else {
            this.$message({
              type: 'error',
              message: errorMsg,
              customClass: 'lai-message-box'
            })
            this.nameDisabled = false
          }
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '服务器异常',
            customClass: 'lai-message-box'
          })
          this.nameDisabled = false
        })
    },
    // 限制名称的输入
    limitInput () {
      const regChina = /^[a-zA-Z0-9\u4e00-\u9fa5·-]+$/
      if (this.staffName.length > 20) {
        this.staffName = this.staffName.substring(0, 20)
      }
      this.staffName = this.staffName.split('')
      this.staffName = this.staffName.filter((e) => {
        return regChina.test(e)
      })
      this.staffName = this.staffName.join('')
    },
    // 动态检查用户信息是否为空
    dynamicCheckUserInfo (callback) {
      let userInfo = localStorage.getItem('userInfo')
      let shopInfo = localStorage.getItem('shopInfo')
      if (userInfo !== null && userInfo !== undefined && userInfo !== '') {
        // 预存memberId
        this.memberId = JSON.parse(userInfo).member?.id
        // 预存shopId
        this.shopId = JSON.parse(shopInfo) ? JSON.parse(shopInfo).id : ''
        callback.apply(this, [userInfo])
      } else {
        let timer = null
        const owner = this
        timer = setInterval(() => {
          userInfo = localStorage.getItem('userInfo')
          shopInfo = localStorage.getItem('shopInfo')

          if (userInfo !== null && userInfo !== undefined && userInfo !== '') {
            clearInterval(timer)
            // 预存memberId
            owner.memberId = JSON.parse(userInfo).member.id
            // 预存shopId ———— 首先确保shopInfo存在
            if (shopInfo) {
              this.shopId = JSON.parse(shopInfo).id
            }
            callback.apply(owner, [userInfo])
          }
        })
      }
    },
    // 打开营销学院网站
    openXueXi () {
      // this.schoolDialogVisible = true
      // 获取用户id
      const info = JSON.parse(localStorage.getItem('userInfo'))
      if (info) {
        this.userId = info.id
      }
      const params = {
        type: 'ylh',
        userId: this.userId
      }
      this.$http.post(CloudUser.getAuthTokenForPc, params)
        .then(res => {
          const { success, code, data, errorMsg } = res.data
          if (success && data && data.aid) {
            const aid = data.aid
            if (CloudUser.getAuthTokenForPc === '/ylh-cloud-service-user-dev/api/page/meeting/auth/token/get-auth-token-for-pc') {
              window.open('https://study-dev.ylhtest.com/study/transfer-login?expid=' + aid + '&studyTerminalCode=1')
            } else if (CloudUser.getAuthTokenForPc === '/ylh-cloud-service-user-pre/api/page/meeting/auth/token/get-auth-token-for-pc') {
              window.open('https://study-pre.ylhtest.com/study/transfer-login?expid=' + aid + '&studyTerminalCode=1')
            } else {
              window.open('https://study.yilihuo.com/study/transfer-login?expid=' + aid + '&studyTerminalCode=1')
            }
          } else if (success && code === 300) {
            this.$message.error('您的账号被冻结，如有问题，请联系海尔业务，如网格主')
          } else if (success && data && data.aid === '') {
            this.$message.error('您今天刚刚注册，请于明天再来学习')
          } else {
            this.$message.error(errorMsg)
          }
        })
    },
    // 待处理业务-分销订单-待处理收款跳转页面
    pendingBusinessOrderTOPage () {
      const url = `/webv2/funds/fund-transaction-manage/payment-confirm?typeId=7&typeName=${encodeURIComponent('分销收款（商城）')}`
      window.location.href = getFullAddress(url)
    },

    // 待处理业务-分销订单-待待审核订单跳转页面
    pendingAuditOrderTOPage () {
      if (!this.shopId) {
        this.$message.warning('未获取到店铺信息，请稍候再试')
        return
      }
      this.$router.push('/webv2/order/distribution-order/list')
    },

    // TODO
    // 获取分销业务-交易数量
    getDistributionBusinessTradeNumber (dateType) {
      let type = 1
      if (dateType === '当日') { type = 0 }
      if (dateType === '当月') { type = 1 }
      if (dateType === '当年') { type = 2 }
      this.$http.get(CloudOrder.distributionBusinessTradeNumber, {
        dateType: type // 日期类型 0当日 1当月 2当年
      }).then(res => {
        if (res.data.success) {
          this.orderItemsCount = res.data.data.orderItemsCount
          this.orderCount = res.data.data.orderCount
          this.orderTotalAmount = res.data.data.orderTotalAmount
            ? res.data.data.orderTotalAmount
            : 0
        }
      })
    },

    // 分销业务-交易数据-查看
    toOrderPage () {
      let startTime = ''
      const endTime = formatDate(new Date(), 'yyyy-MM-dd 23:59:59')
      if (this.dataFilter === '当日') {
        startTime = formatDate(new Date(), 'yyyy-MM-dd 00:00:00')
      }
      if (this.dataFilter === '当月') {
        startTime = formatDate(new Date(), 'yyyy-MM-01 00:00:00')
      }
      if (this.dataFilter === '当年') {
        startTime = formatDate(new Date(), 'yyyy-01-01 00:00:00')
      }
      if (!this.shopId) {
        this.$message.warning('未获取到店铺信息，请稍候再试')
        return
      }
      this.$router.push({
        path: '/webv2/order/distribution-order/list',
        query: {
          createTimeStart: `${startTime}`,
          createTimeEnd: `${endTime}`,
          id: `${this.shopId}`
        }
      })
    },
    // TODO
    // 获取分销业务-目标汇总
    getDistributionBusinessTargetSum (dateType) {
      let type = 2
      if (dateType === '月度') { type = 2 }
      if (dateType === '年度') { type = 1 }
      this.$http.post(CloudOrder.searchTargetAndActualSalesDetailsAmountV2, {
        targetTypeId: type, // 目标类型 2月度 1年度
        startTime:
          type === 1
            ? formatDate(new Date(), 'yyyy-01-01')
            : formatDate(new Date(), 'yyyy-MM-01'),
        endTime: formatDate(new Date(), 'yyyy-MM-dd')
      }).then(res => {
        if (res.data.success) {
          this.sumTargetAmount = res.data.data.targetAmount
          this.sumActualAmount = res.data.data.actualSalesDetailsAmount
          this.rate = res.data.data.ratio || '0%'
        }
      })
    },

    // 分销业务-目标汇总-查看
    toTargetPage () {
      if (!this.shopId) {
        this.$message.warning('未获取到店铺信息，请稍候再试')
        return
      }
      const url = '/webv2/customer/customer-target-confirmation'
      window.location.href = getFullAddress(url)
    },
    // TODO
    // 获取零售业务-全部门店
    getRetailBusinessSite (dateType) {
      let startTime = ''
      const endTime = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')

      if (dateType === '当日') {
        startTime = formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
      }
      if (dateType === '当月') {
        startTime = formatDate(new Date(), 'yyyy-MM') + '-01 00:00:00'
      }
      if (dateType === '当年') {
        startTime = new Date().getFullYear() + '-01-01 00:00:00'
      }
      this.$http.post(CloudOrder.retailBusinessSiteData, {
        createTimeEnd: new Date(endTime.replace(/-/g, '/')).getTime(),
        createTimeStart: new Date(startTime.replace(/-/g, '/')).getTime(),
        memberId: this.memberId
      }).then(res => {
        if (res.data.success) {
          this.siteDealAmount = res.data.data.dealAmount
          this.siteDealPersonCount = res.data.data.dealPersonCount
          this.siteOrderCount = res.data.data.orderCount
          this.siteOrderSkuCount = res.data.data.orderSkuCount
        }
      })
    },

    // 零售销售-全部门店-跳转相关页面地址
    toRetailPage (url) {
      let startTime = ''
      const endTime = formatDate(new Date(), 'yyyy-MM-dd')
      if (this.siteFilter === '当日') {
        startTime = endTime
      }
      if (this.siteFilter === '当月') {
        startTime = formatDate(new Date(), 'yyyy-MM-01')
      }
      if (this.siteFilter === '当年') {
        startTime = formatDate(new Date(), 'yyyy-01-01')
      }
      window.location.href =
        getFullAddress(url) + `?startTime=${startTime}&endTime=${endTime}`
    },

    // 跳转库存报表
    toInventoryPriceReport () {
      const url = '/webv2/stock/inventory-price-report/list'
      window.location.href = getFullAddress(url)
    },
    // 获取待处理业务-库存待办
    getPendingStockData (dateType) {
      let startTime = ''
      if (dateType === '近一个月') {
        startTime = `${this.getRecentDay(-30)} 00:00:00`
      }
      if (dateType === '近三个月') {
        startTime = `${this.getRecentDay(-90)} 00:00:00`
      }
      if (dateType === '近半年') {
        startTime = `${this.getRecentDay(-180)} 00:00:00`
      }

      this.$http.post(CloudStock.pendingStockData, {
        memberId: this.memberId,
        startTime,
        endTime: `${this.getRecentDay(0)} 23:59:59`
      }).then(res => {
        if (res.data.success) {
          this.waitDeliveryOrderCount = res.data.data.waitDeliveryOrderCount
          this.waitInboundOrderCount = res.data.data.waitInboundOrderCount
          this.waitOutboundOrderCount = res.data.data.waitOutboundOrderCount
          this.waitReciveOrderCount = res.data.data.waitReciveOrderCount
        }
      })
    },

    showStockWaitDialog (flag, stockDataFilter) {
      this.$refs['stock-wait-dialog-ref'].open(flag, stockDataFilter)
    },

    getRecentDay (day) {
      const today = new Date()
      const milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(milliseconds)
      const tYear = today.getFullYear()
      let tMonth = today.getMonth()
      let tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },

    doHandleMonth (month) {
      let m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    },
    // TODO
    // 获取待处理业务-销售订单
    getPendingSaleOrderData () {
      this.$http.get(CloudOrder.pendingSaleOrderData).then(res => {
        if (res.data.success) {
          this.auditCount = res.data.data.auditCount
          this.collectionAmt = res.data.data.collectionAmt
          this.collectionCount = res.data.data.collectionCount
        }
      })
    },
    // 获取零售业务-库存详情
    getRetailBusinessStockData () {
      this.$http.post(CloudStock.retailBusinessStockData, {
        memberId: this.memberId
      }).then(res => {
        if (res.data.success) {
          this.inboundSkuCount = res.data.data.inboundSkuCount
          this.outboundSkuCount = res.data.data.outboundSkuCount
          this.stockProductCount = res.data.data.stockProductCount
          this.stockSkuCount = res.data.data.stockSkuCount
        }
      })
    },

    // 获取库存信息
    getstockinfocount () {
      this.$http.get(CloudBi.getstockinfocount, {
        memberId: this.memberId
      }).then(res => {
        if (res.data.success) {
          this.storeAvgAge = res.data.data.storeAvgAge
          this.storeDaysInventory = res.data.data.storeDaysInventory
          this.inventoryTime = res.data.data.inventoryTime
        }
      })
    },

    // 获取aid
    getAid () {
      this.$http.get(CloudUser.getAid, {
        sellerCode: ''
      }).then(res => {
        if (res.data.success) {
          console.log(res.data)
          this.aid = res.data.data
        }
      })
    },
    // 你可能关心的相关数据
    getMayCareData () {
      this.$http.get(CloudGoods.homeMayCareData, {
        memberId: this.memberId
      }).then(res => {
        if (res.data.success) {
          this.averageSampleTime = res.data.data.averageSampleTime
          this.itemModelPercentage = res.data.data.itemModelPercentage
          this.sampleCount = res.data.data.sampleCount
        }
      })
    },
    // 初始化权限码控制
    initSetPermission () {
      // 待处理业务-分销订单-待处理收款是否有权限
      this.pendingReceivablesPermission = hasPermission('fund-collection')
      // 待处理业务-分销订单-待审核订单是否有权限
      this.pendingAuditOrderPermission = hasPermission('order-confirm')
      // 待处理业务-零售订单-是否有权限
      this.pendingRetailOrderPermission = hasPermission('yd-order-list')
      // 待处理业务-库存待办-待发货是否有权限
      this.pendingStockShipmentPermission = hasPermission('common-stock-deliver')
      // 待处理业务-库存待办-待出库是否有权限
      this.pendingStockOutPermission = hasPermission('common-stock-out')
      // 待处理业务-库存待办-待收货是否有权限
      this.pendingStockReceivePermission = hasPermission(
        'common-stock-receiving'
      )
      // 待处理业务-库存待办-待入库是否有权限
      this.pendingStockInPermission = hasPermission('common-stock-put')
      // 分销业务-目标数据(当月汇总)-是否有权限
      this.orderTargetPermission = hasPermission('summarytarget')
      if (this.orderTargetPermission) {
        // 有权限则获取分销业务-目标汇总
        this.getDistributionBusinessTargetSum(this.summaryFilter)
      }
      // 分销业务-交易数据-是否有权限
      this.orderDealDataPermission = hasPermission('member-order-read')
      // 零售业务-全部门店-是否有权限
      this.retailSitePermission = hasPermission('yd-order-list')
      // 零售业务-库存-是否有权限
      this.retailStockPermission = hasPermission('common-store-query')
      // 公告-官方公告是否有权限
      this.officialInformationPermission = hasPermission('shopmall-purchase')
      // 没有官方公告的权限，显示商家公告
      this.officialInformation = this.officialInformationPermission
    },

    // 过去名字是否合规定
    getUserStaffInfo () {
      this.$http.get(CloudUser.getUserStaffInfo)
        .then(res => {
          if (res.data.success) {
            if (res.data.data.modifiedFlag === 'Y') {
              this.editName = false
            }
            if (res.data.data.modifiedFlag === 'N') {
              this.editName = true
            }
            this.yuanStaffName = res.data.data.staffName
          } else {
            this.$message({
              type: 'error',
              message: res.data.errorMsg,
              customClass: 'lai-message-box'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '服务器异常',
            customClass: 'lai-message-box'
          })
        })
    },
    getTaskNum () {
      this.$http.get(CloudTask.getTaskNum).then(res => {
        if (res.data.success) {
          this.taskObj = res.data.data || {}
        } else {
          this.$message.error(res.data.errorMsg)
        }
      })
    },
    // 获取插屏广告
    getAds () {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      if (info) {
        this.userId = info.id
      }
      const deviceCode =
        !this.memberId || !this.userId ? null : this.memberId + '_' + this.userId
      console.log(deviceCode)
      this.$http.post(CloudUser.getAdvertInfoList, {
        positionCode: 'ylh-pc-main',
        adTypeCode: 'insert',
        deviceCode: deviceCode
      }).then(res => {
        if (res?.data?.success && res?.data?.data && res.data.data.length) {
          this.adList1 = res.data.data[0]
          this.adDialogVisible = true

          if (this.adList1?.topImageUrl && this.adList1.topImageUrl.indexOf('ecm/images/no-img.png') !== -1) {
            this.adList1.topImageUrl = ''
          }
          if (this.adList1?.middleImageUrl && this.adList1.middleImageUrl.indexOf('ecm/images/no-img.png') !== -1) {
            this.adList1.middleImageUrl = ''
          }
          if (this.adList1?.bottomImageUrl && this.adList1.bottomImageUrl.indexOf('ecm/images/no-img.png') !== -1) {
            this.adList1.bottomImageUrl = ''
          }
        } else {
          this.adDialogVisible = false
        }
      })
    },

    // 广告界面跳转
    goToAdvertPage (obj, isFalg) {
      if (isFalg) {
        this.advertCount(obj.id, 'ylh-pc-main', 'insert')
        this.adDeviceLogCount(obj.id)
      } else {
        this.advertCount(obj.id, 'ylh-pc-right-login', 'banner-pc')
      }
      if (obj.source) {
        this.$http.get(CloudUser.getCompleteJumpUrl, {
          source: obj.source,
          linkUrl: obj.pcUrl
        })
          .then(res => {
            if (res.data.success) {
              window.open(decodeURIComponent(res.data.data))
            }
          })
          .catch(() => {
            this.$message.error('服务器异常')
          })
      } else {
        // 链接
        if (obj.linkType === 1) {
          const webUrl = location.origin + '/webv2/advert-detail/' + obj.id
          self.location.href = webUrl
        } else if (obj.linkType === 2) {
          // 链接
          if (obj.linkUrlType === 'INNER') {
            // 内部链接
            if (obj.authorizationCode) {
              this.adPermission = hasPermission(obj.authorizationCode)
              if (this.adPermission) {
                self.location.href = location.origin + obj.h5Url
              } else {
                this.$message.error('无权限查看')
              }
            } else {
              self.location.href = location.origin + obj.h5Url.trim()
            }
          } else {
            // 外链
            const url = obj.pcUrl
            const hostUrl = url.split('/')
            if (
              hostUrl[2] === 'help.yilihuo.com' ||
              hostUrl[2] === 'h.yilihuo.com'
            ) {
              obj.pcUrl =
                obj.pcUrl +
                '?JSESSIONID=' +
                localStorage.getItem('sessionId') +
                '&type=1'
            }
            if (obj.h5UrlType === 3) {
              const tokenData = JSON.parse(localStorage.getItem('tokenData'))
              // 8码不存在直接返回
              if (!tokenData?.seller_code) { return }
              obj.pcUrl = obj.pcUrl.includes('?')
                ? `${obj.pcUrl}&userId=${tokenData?.seller_code}&personnelUserId=${this.userId}`
                : `${obj.pcUrl}?userId=${tokenData?.seller_code}&personnelUserId=${this.userId}`
            }
            self.location.href = obj.pcUrl.trim()
          }
        }
      }
    },

    // 广告上报
    adDeviceLogCount (ids) {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      this.$http.post(CloudUser.setAdDeviceLog, {
        adId: ids,
        adTypeCode: 'insert',
        deviceCode: info.member.id + '_' + info.id
      }).then(res => {
        if (!res.data.success) {
          console.log(res.data.errorMessage)
        }
      })
      this.adDialogVisible = false
    },

    // 广告点击统计
    advertCount (ids, adPositionCode, adTypeCode) {
      this.$http.post(CloudUser.insertYlhAdcountLog, {
        adPositionCode: adPositionCode,
        adTypeCode: adTypeCode,
        adId: ids
      }).then(res => {
        if (!res.data.success) {
          console.log(res.data.errorMessage)
        }
      })
    },

    // 获取查询定制公告通道一级分类
    getpassagelevelist () {
      this.$http.get(CloudNotice.getmsgunreadinfo, {
      }).then(res => {
        if (res.data.success && res.data.data && res.data.data.resultDtoList && res.data.data.resultDtoList.length > 0) {
          this.passagelevelist = res.data.data.resultDtoList || []
          this.showTap = res.data.data.showTap || false
          this.noticeTypeActive = this.passagelevelist[0].showTypeId
          this.getlevelist(this.noticeTypeActive)
        }
      })
    },

    // 选择 一级
    choseleve () {
      this.getlevelist(this.noticeTypeActive)
    },

    // 查询一级分类下最新五条消息
    getlevelist (showTypeId) {
      this.$http.post(CloudNotice.searchmsgsysteminfo, {
        pageNum: 1,
        pageSize: 5,
        showTypeId: showTypeId
      }).then(res => {
        if (res.data.success && res.data.data && res.data.data.list && res.data.data.list.length > 0) {
          this.levelist = res.data.data.list || []
        }
      })
    },
    pendingBusinessRefresh (type) {
      if (type === 1) {
        if (this.pendingBusinessRefreshLoading) { return }
        this.pendingBusinessRefreshLoading = true
        const pendingAuditOrder = () => {
          return new Promise((resolve, reject) => {
            this.$http.get(CloudOrder.pendingAuditOrder, { refresh: 1 }).then(res => {
              if (res.data.success) {
                this.noAduitCount = res.data.data.noAduitCount
                resolve(true)
              } else {
                reject(res.data.errorMsg)
              }
            }).catch((err) => {
              reject(err)
            })
          })
        }
        Promise.all([pendingAuditOrder()]).then(() => {
          this.pendingBusinessRefreshLoading = false
        }).catch(() => {
          this.pendingBusinessRefreshLoading = false
        })
      } else if (type === 2) {
        if (this.pendingBusinessRefreshLoading) { return }
        this.pendingRetailRefreshLoading = true
        const pendingSaleOrderData = () => {
          return new Promise((resolve, reject) => {
            this.$http.get(CloudOrder.pendingSaleOrderData, { refresh: 1 }).then(res => {
              if (res.data.success) {
                this.auditCount = res.data.data.auditCount
                this.collectionAmt = res.data.data.collectionAmt
                this.collectionCount = res.data.data.collectionCount
                resolve(true)
              } else {
                reject(res.data.errorMsg)
              }
            }).catch((err) => {
              reject(err)
            })
          })
        }
        Promise.all([pendingSaleOrderData()]).then(() => {
          this.pendingRetailRefreshLoading = false
        }).catch(() => {
          this.pendingRetailRefreshLoading = false
        })
      }
    },
    retailBusinessRefresh () {
      if (this.retailBusinessRefreshLoading) { return }
      this.retailBusinessRefreshLoading = true
      let startTime = ''
      const endTime = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')

      if (this.siteFilter === '当日') {
        startTime = formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
      }
      if (this.siteFilter === '当月') {
        startTime = formatDate(new Date(), 'yyyy-MM') + '-01 00:00:00'
      }
      if (this.siteFilter === '当年') {
        startTime = new Date().getFullYear() + '-01-01 00:00:00'
      }

      this.$http.post(CloudOrder.retailBusinessSiteData, {
        createTimeEnd: new Date(endTime.replace(/-/g, '/')).getTime(),
        createTimeStart: new Date(startTime.replace(/-/g, '/')).getTime(),
        memberId: this.memberId,
        refresh: 1
      }).then(res => {
        if (res.data.success) {
          this.siteDealAmount = res.data.data.dealAmount
          this.siteDealPersonCount = res.data.data.dealPersonCount
          this.siteOrderCount = res.data.data.orderCount
          this.siteOrderSkuCount = res.data.data.orderSkuCount
        }
      }).finally(() => {
        this.retailBusinessRefreshLoading = false
      })
    },
    distributionBusinessRefresh () {
      if (this.distributionBusinessRefreshLoading) { return }
      this.distributionBusinessRefreshLoading = true
      const distributionBusinessTradeNumber = () => {
        return new Promise((resolve, reject) => {
          let type = 1
          if (this.dataFilter === '当日') { type = 0 }
          if (this.dataFilter === '当月') { type = 1 }
          if (this.dataFilter === '当年') { type = 2 }
          this.$http.get(CloudOrder.distributionBusinessTradeNumber, {
            dateType: type, // 日期类型 0当日 1当月 2当年
            refresh: 1
          }).then(res => {
            if (res.data.success) {
              this.orderItemsCount = res.data.data.orderItemsCount
              this.orderCount = res.data.data.orderCount
              this.orderTotalAmount = res.data.data.orderTotalAmount
                ? res.data.data.orderTotalAmount
                : 0
              resolve(true)
            } else {
              reject(res.data.errorMsg)
            }
          }).catch((err) => {
            reject(err)
          })
        })
      }
      const searchTargetAndActualSalesDetailsAmountV2 = () => {
        return new Promise((resolve, reject) => {
          let type = 2
          if (this.summaryFilter === '月度') { type = 2 }
          if (this.summaryFilter === '年度') { type = 1 }
          this.$http.post(CloudOrder.searchTargetAndActualSalesDetailsAmountV2, {
            targetTypeId: type, // 目标类型 2月度 1年度
            startTime:
              type === 1
                ? formatDate(new Date(), 'yyyy-01-01')
                : formatDate(new Date(), 'yyyy-MM-01'),
            endTime: formatDate(new Date(), 'yyyy-MM-dd'),
            refresh: 1
          }).then(res => {
            if (res.data.success) {
              this.sumTargetAmount = res.data.data.targetAmount
              this.sumActualAmount = res.data.data.actualSalesDetailsAmount
              this.rate = res.data.data.ratio || '0%'
              resolve(true)
            } else {
              reject(res.data.errorMsg)
            }
          }).catch((err) => {
            reject(err)
          })
        })
      }

      Promise.all([distributionBusinessTradeNumber(), searchTargetAndActualSalesDetailsAmountV2()]).then(() => {
        this.distributionBusinessRefreshLoading = false
      }).catch(() => {
        this.distributionBusinessRefreshLoading = false
      })
    },
    async download (url) {
      if (!url) {
        if (window.gio) { window.gio('track', 'j9y64qaR_017911') }
        await fetch(`${CloudBase.getAppVersionAndModule}?systemVersion=ylh_pc`, { method: 'GET', credentials: 'omit' })
          .then(_ => _.json())
          .then(data => {
            if (data.success) {
              url = (data.data || [])
                .find(_ => _.moduleCode === 'win32')
                ?.downloadUrl || ''
            } else {
              this.$message.error(data.errorMsg || '网络异常，请稍后再试~')
            }
          })
          .catch(() => {
            this.$message.error('网络异常，请稍后再试~')
          })

        if (!url) {
          return
        }
      }

      window.open(url, '_blank', 'noopener noreferrer')
    }
  },
  created () {
    // 清空面包屑
    this.$store.commit('changeBreadcrumbList', [])
    // 名字是否合规
    this.getUserStaffInfo()
    this.getAid() // 获取aid
    this.getAds()

    // 初始化权限码控制
    this.initSetPermission()
    // 登录后初始化异步赋值
    bus.$on('request-permissionList-success', () => {
      this.initSetPermission()
    })

    // 动态检查用户信息是否为空
    this.dynamicCheckUserInfo(userInfoString => {
      // 用户信息对象
      const userInfo = JSON.parse(userInfoString)
      this.userPhoneNumber = userInfo.userPhone
      if (this.userPhoneNumber) {
        this.userPhoneNumberI = this.userPhoneNumber.replace(
          /(\d{3})\d*(\d{4})/,
          '$1****$2'
        )
        this.isTrueFalse = false
      }

      // 获取会员库存是否设置为简流程
      this.$http.get(CloudOrder.searchSimpleProcessStatus, {
        memberId: userInfo.member.id
      }).then(res => {
        if (res.data.success) {
          this.inStockSimpleProcess = res.data.data[0].ifInstoreConfirm === 2
          this.outStockSimpleProcess = res.data.data[0].ifOutwareConfirm === 2
        }
      })

      // 获取零售业务-全部门店
      this.getRetailBusinessSite(this.siteFilter)
      // 获取待处理业务-库存待办
      this.getPendingStockData('近一个月')
      // 获取零售业务-库存详情
      this.getRetailBusinessStockData()
      // 获取库存信息
      this.getstockinfocount()
      // 你可能关心的相关数据
      this.getMayCareData()
      // 公告列表
      this.getpassagelevelist()
      // 我的任务
    })

    this.getTaskNum()

    // 获取右侧广告部分
    this.$http.post(CloudUser.getAdvertInfoList, {
      positionCode: 'ylh-pc-right-login',
      adTypeCode: 'banner-pc'
    }).then(res => {
      if (res?.data?.success) {
        if (res?.data?.data?.length > 0) {
          res.data.data.forEach((item) => {
            if (item.pcUrl?.includes('2022.html')) {
              item.pcUrl = item.pcUrl + '&aid=' + this.aid
            }
          })
        }
        this.adList = res.data.data
      }
    })

    // 获取功能推荐列表
    this.$http.post(CloudUser.commonMenu, {
      type: 2 // 2: pc功能推荐 3：pc常用菜单
    }).then(res => {
      if (res.data.success) {
        this.recommendMenuList = res.data.data
      }
    })

    // 获取待处理业务-分销订单-待处理收款
    this.$http.get(CloudFund.pendingReceivables).then(res => {
      if (res.data.success) {
        this.sumOrder = res.data.data.orderNum
        this.sumAmount = res.data.data.orderAmount
      }
    })
    // TODO
    // 获取待处理业务-分销订单-待审核订单
    this.$http.get(CloudOrder.pendingAuditOrder).then(res => {
      if (res.data.success) {
        this.noAduitCount = res.data.data.noAduitCount
      }
    })

    // 获取分销业务-交易数量
    this.getDistributionBusinessTradeNumber(this.dataFilter)
    // 获取待处理业务-销售订单
    this.getPendingSaleOrderData()

    // 获取智家云店tv版apk下载地址
    this.$http
      .get(CloudBase.getAppVersion, {
        systemVersion: 'tv_ylh'
      })
      .then(res => {
        if (res.data.success) {
          this.tvYlhApkUrl = res.data.data[0].downloadUrl
        }
      })

    // 首页上报
    if (window.gio) { window.gio('track', 'ylh_pc_home') }
  }
}
