import { CloudStock } from '../../../apis'
import { getFullAddress } from '../../../utils'

export default {
  data () {
    return {
      show: false,
      flag: 0,
      search: {
        codeOrName: '',
        brandId: '',
        productGroupCodeList: []
      },
      titleList: ['发货', '出库', '收货', '入库'],
      brandList: [],
      productGroupsOptions: [], // 产品组
      tableData: [],
      // 表格 - loading
      loading: false,
      // 当前页
      pageNum: 1,

      // 页容量
      pageSize: 10,
      // 总条数
      total: 0,
      selectedList: [],
      stockDataFilter: null
    }
  },
  methods: {
    open (flag, stockDataFilter) {
      this.show = true
      this.flag = flag
      this.stockDataFilter = stockDataFilter
      this.handleSearch()
    },

    /**
     * 关闭弹窗，刷新列表
     */
    handleClose () {
      this.$emit('reload')
      this.show = false
    },

    // 触发查询
    handleSearch () {
      this.pageNum = 1
      this.getTableList()
    },

    // 页码容量变化
    handleSizeChange (pageSize) {
      this.pageNum = 1
      this.pageSize = pageSize
      this.getTableList()
    },

    // 当前页码变化
    handleCurrentChange (pageNum) {
      this.pageNum = pageNum
      this.getTableList()
    },
    getTableList () {
      let startTime = ''
      if (this.stockDataFilter === '近一个月') {
        startTime = `${this.getRecentDay(-30)} 00:00:00`
      }
      if (this.stockDataFilter === '近三个月') {
        startTime = `${this.getRecentDay(-90)} 00:00:00`
      }
      if (this.stockDataFilter === '近半年') {
        startTime = `${this.getRecentDay(-180)} 00:00:00`
      }
      const params = {
        memberId: JSON.parse(localStorage.getItem('userInfo')).member.id || '',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        startTime,
        endTime: `${this.getRecentDay(0)} 23:59:59`,
        flag: this.flag
      }
      this.loading = true
      this.$http
        .post(CloudStock.searchStockWaitInfoList, params)
        .then(resp => {
          const { success, data, errorMsg } = resp.data
          if (success) {
            this.tableData = data.list
            this.total = data.total
            // 回到列表顶部
            const table = this.$refs.list.$el.querySelector(
              '.el-table__body-wrapper'
            )
            if (table) {
              table.scrollTo(0, 0)
            }
          } else {
            this.$message.error(errorMsg || '服务器错误')
            this.tableData = []
            this.total = 0
          }
        })
        .catch(() => {
          this.$message.error('服务器错误')
          this.tableData = []
          this.total = 0
        })
        .finally(() => {
          this.loading = false
        })
    },

    getRecentDay (day) {
      const today = new Date()
      const milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(milliseconds)
      const tYear = today.getFullYear()
      let tMonth = today.getMonth()
      let tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },

    doHandleMonth (month) {
      let m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    },

    operate (row) {
      const url = [`/webv2/stock/shipment-confirm?orderId=${row.id}&orderCode=${row.orderCode}&_closeable`,
        `/cloud/#!/accounting/outStoreConfirm/${row.id}/`,
        `/webv2/stock/receiving-confirm?id=${row.id}&_closeable`,
        `/cloud/#!/accounting/warehousingConfirm/${row.id}`][this.flag - 1]
      window.open(getFullAddress(url))
    }
  }
}
