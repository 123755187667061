import { CloudTask } from '../../../apis'
import { hasPermission } from '../../../utils'
export default {
  data () {
    return {
      radioActive: null,
      taskTypeList: [],
      todolist: [],
      showAssign: false // 待分配是否显示
    }
  },
  methods: {
    // 功能权限
    hasPermission (permissionCode) {
      if (hasPermission('Customer-task-assignment')) {
        this.showAssign = true
      }
      this.getTaskNum(true)
    },
    radioChange () {
      this.getTaskNum()
      this.getListTask()
    },
    getListTask () {
      let orderBy
      if (this.radioActive === '1' || this.radioActive === '2') {
        orderBy = 1
      } else {
        orderBy = 4
      }
      this.$http.post(CloudTask.listTask, {
        pageNum: 1,
        pageSize: 5,
        tabType: this.radioActive, // 1-待分配；2-待办；3-已办
        orderBy
      }).then(res => {
        if (res.data.success) {
          this.todolist = res.data.data.list || []
          console.log(this.todolist)
        } else {
          this.$message.error(res.data.errorMsg)
        }
      })
    },
    // 获取查询定制公告通道一级分类
    getTaskNum (isFirst) {
      this.$http.get(CloudTask.getTaskNum).then(res => {
        if (res.data.success) {
          const { toBeAllocateNum, toBeDealNum, completeNum } = res.data.data
          this.taskTypeList = []
          if (this.showAssign && toBeAllocateNum) {
            this.taskTypeList.push({
              label: '待分配',
              value: '1',
              num: toBeAllocateNum
            })
          }
          if (toBeDealNum) {
            this.taskTypeList.push({
              label: '待办',
              value: '2',
              num: toBeDealNum
            })
          }
          if (completeNum) {
            this.taskTypeList.push({
              label: '已办',
              value: '3',
              num: completeNum
            })
          }
          if (isFirst) {
            this.radioActive = this.taskTypeList[0]?.value
          }
          if (this.radioActive) {
            this.getListTask()
          }
        } else {
          this.$message.error(res.data.errorMsg)
        }
      })
    },
    toTaskList () {
      window.open('/pc-task/task-list')
    },

    toDetails (task) {
      if (task.taskType === 4) {
        window.open('/pc-task/self-task-manage/self-task-detail?taskId=' + task.taskId)
      } else {
        window.open('/pc-task/task-detail?taskId=' + task.taskId)
      }
    }
  },

  created () {
    this.hasPermission('Customer-task-assignment')
  }
}
